import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/auth/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  activeLang: string = 'EN';
  loading: boolean = false;
  errMesege: string;
  private langChangesSubscription: Subscription;
  signupForm: FormGroup;


  formErrors = {
    name: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  validationMessages = {
    name: {
      required: 'Name is required.'
    },
    lastName: {
      required: 'Last Name is required.'
    },
    email: {
      required: 'Email is required.',
      email: 'Please enter a valid email address.'
    },
    password: {
      required: 'Password is required.'
    },
    confirmPassword: {
      required: 'Confirm Password is required.'
    },
  };

  constructor(
    private _translocoService: TranslocoService,
    private fb: FormBuilder,
    private _authService: AuthService,
    public router: Router,) { }

  ngOnInit(): void {
    this.createForm();
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  createForm() {
    this.signupForm = this.fb.group({
      name: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    },
      { validator: this.checkIfMatchingPasswords("password", "confirmPassword") }
    );
    this.signupForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // Reset validation messages now
  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }

  onValueChanged(data?: any) {
    if (!this.signupForm) {
      return;
    }
    const form = this.signupForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit(): void {
    this.loading = true;
    if (this.signupForm.invalid) {
      this.loading = false;
      return;
    }

    this.signupForm.disable();
    let chatId = "000000000000000000000000";
    let localChatId = localStorage.getItem('chatId');
    if (localChatId) {
      chatId = localChatId;
    }
    this._authService.signUp({ ...this.signupForm.value, usedLang: this.activeLang, chatId: chatId }).subscribe(
      () => {
        // if (localChatId) {
        //   localStorage.removeItem('chatId');
        // }
        this.router.navigate([this.activeLang + "/sign_in"]);

      },
      (response) => {
        this.loading = false;
        this.signupForm.enable();
        this.errMesege = response.error.err.message;
      }
    );
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }

}
