import { NgModule, CUSTOM_ELEMENTS_SCHEMA, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { SearchComponent } from './search/search.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MetaTagResolver } from './resolvers/metaTitle.resolver';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { ProductComponent } from './product/product.component';
import { ContactComponent } from './contact/contact.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { SignUpComponent } from './sign-up/sign-up.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AuthService } from './auth/auth.service';
import { PartOverviewComponent } from './part-overview/part-overview.component';
import { SendOfferReqComponent } from './send-offer-req/send-offer-req.component';
import { EquipmentCategoriesComponent } from './equipment_categories/equipment_categories.component';
import { MachinesComponent } from './machines/machines.component';
import { MatSliderModule } from '@angular/material/slider';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { BlogsComponent } from './blogs/blogs.component';
import { NewsComponent } from './news/news.component';
import { NewsDetailsComponent } from './news-details/news-details.component';
import { ByBagsProductsComponent } from './by-bags-products/by-bags-products.component';
import { ByBagProductComponent } from './by-bag-product/by-bag-product.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ManagerWordComponent } from './manager-word/manager-word.component';
import { SearchMachinesComponent } from './search-machines/search-machines.component';
import { ChatComponent } from './chat/chat.component';
import { CatalogsComponent } from './catalogs/catalogs.component';
import { MachineResolver } from './resolvers/machine.resolver';
import { ThreeDViewerDialogComponent } from './three-dviewer-dialog/three-dviewer-dialog';
import { ComplaintsSuggestionsComponent } from './complaints-suggestions/complaints-suggestions.component';
// import { TranslocoModule } from '@ngneat/transloco';
import { register } from 'swiper/element/bundle';
import { ProcessHttpmsgService } from './services/process-httpmsg.service';
import { AuthInterceptor } from './auth/auth.interceptor';
import { DataProtictionComponent } from './privacy_policies/data-protiction/data-protiction.component';
import { CookiesComponent } from './privacy_policies/cookies/cookies.component';
import { PrivacyPolicyComponent } from './privacy_policies/privacy-policy/privacy-policy.component';

// Swiper
register();


@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    TruncatePipe,
    FooterComponent,
    HomeComponent,
    HeaderComponent,
    ProductComponent,
    ContactComponent,
    SignUpComponent,
    SignInComponent,
    PartOverviewComponent,
    SendOfferReqComponent,
    EquipmentCategoriesComponent,
    MachinesComponent,
    BlogDetailsComponent,
    BlogsComponent,
    NewsComponent,
    NewsDetailsComponent,
    ByBagsProductsComponent,
    ByBagProductComponent,
    AboutUsComponent,
    ManagerWordComponent,
    SearchMachinesComponent,
    ChatComponent,
    CatalogsComponent,
    ThreeDViewerDialogComponent,
    ComplaintsSuggestionsComponent,
    DataProtictionComponent,
    CookiesComponent,
    PrivacyPolicyComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatIconModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    MatSelectModule
    // TranslocoModule
  ],
  providers: [
    ProcessHttpmsgService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MetaTagResolver,
    AuthService,
    MachineResolver,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
