<div
  class="w-11/12 xl:w-2/3 center bg-[#dedede17] shadow-md rounded-xl"
  [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'"
>
  <p class="text-center font-semibold py-5 text-lg lg:text-xl">
    {{ "areYouInter" | transloco }}
  </p>
  <form *ngIf="contactForm" [formGroup]="contactForm" autocomplete="off">
    <div
      class="grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10 justify-around px-5"
    >
      <mat-form-field appearance="outline">
        <mat-label> {{ "companyName" | transloco }} </mat-label>
        <input
          matInput
          type="text"
          formControlName="companyName"
          [placeholder]="'EnteryourCompanyName' | transloco"
        />
        <mat-error *ngIf="formErrors.companyName">{{
          formErrors.companyName
        }}</mat-error>
      </mat-form-field>

      <mat-form-field
        class="mr-3 w-full"
        appearance="outline"
        id="countryInput"
      >
        <mat-label>{{ "country" | transloco }}</mat-label>
        <input
          type="text"
          placeholder="Enter your country"
          aria-label="Country"
          matInput
          formControlName="country"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            *ngFor="let country of filteredCountries"
            [value]="country.name"
            (click)="setPhoneCode(country.phoneCode)"
          >
            <div class="flex items-center">
              <div class="w-20">{{ country.phoneCode }}</div>
              <div>{{ country.name }}</div>
            </div>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ "email" | transloco }}</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          [placeholder]="'enterYourEmail' | transloco"
        />
        <mat-error *ngIf="formErrors.email">{{ formErrors.email }}</mat-error>
      </mat-form-field>
      <div class="flex">
        <mat-form-field
          class="w-3/12"
          appearance="outline"
          id="phonePrefixInput"
        >
          <input
            matInput
            type="text"
            formControlName="prefix"
            placeholder="+90"
            name="prefix"
          />
          <mat-error *ngIf="formErrors.prefix">{{
            formErrors.prefix
          }}</mat-error>
        </mat-form-field>
        <mat-form-field
          class="flex-grow w-9/12"
          appearance="outline"
          id="phoneNumberInput"
        >
          <input
            matInput
            type="tel"
            formControlName="phoneNumber"
            placeholder="530 148 56 65"
            name="phoneNumber"
          />
          <mat-error *ngIf="formErrors.phoneNumber">{{
            formErrors.phoneNumber
          }}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{ "howReachUs" | transloco }}</mat-label>
        <mat-select formControlName="howReachUs">
          <mat-option value="Google"> Google </mat-option>
          <mat-option value="Youtube"> Youtube </mat-option>
          <mat-option value="Friend"> Friend </mat-option>
          <mat-option value="Exhibition"> Exhibition </mat-option>
          <mat-option value="Facebook"> Facebook </mat-option>
          <mat-option value="Instagram"> Instagram </mat-option>
          <mat-option value="X (Twitter)"> X (Twitter) </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="viewRecieveMessage">
      <p class="text-center text-green-500">
        We have received your message and will respond as soon as possible
      </p>
    </div>
    <div class="py-4">
      <button
        type="button"
        (click)="onSubmit()"
        class="bg-[#E60027] text-white px-4 py-2 center rounded-md disabled:bg-[#ff798f] hover:bg-[#a7001c]"
      >
        {{ "sendRequest" | transloco }}
      </button>
    </div>
  </form>
</div>
