<div class="relative w-full top-0 h-[4.6rem] bg-gray-900"></div>

<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="hidden xl:block">
    <div class="w-full">
      <img src="assets\contact\map.webp" width="100%" alt="Map" />
    </div>
    <div class="text-white text-center relative -top-52">
      <div class="flex justify-center items-center">
        <p class="rounded-xl bg-[#1d1d1d] px-4 py-2 text-4xl font-extrabold">
          {{ "gitInToutch" | transloco }}
        </p>
      </div>
    </div>
  </div>

  <div class="flex items-center justify-center mt-10 xl:mt-24 mb-4">
    <p
      class="text-center inline-block text-3xl border border-black px-5 py-1 rounded-full"
    >
      {{ "contact" | transloco }}
    </p>
  </div>
  <div
    class="w-11/12 xl:w-9/12 center xl:p-8 rounded-2xl shadow-lg bg-[#dedede17]"
  >
    <form
      *ngIf="contactForm"
      [formGroup]="contactForm"
      (ngSubmit)="onSubmit()"
      autocomplete="off"
    >
      <div class="lg:flex xl:flex">
        <mat-form-field class="mr-3 w-full" appearance="outline" id="nameInput">
          <mat-label> {{ "name" | transloco }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="name"
            placeholder="Enter your name"
            name="name"
          />
          <mat-error *ngIf="formErrors.name">{{ formErrors.name }}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-full" appearance="outline" id="lastNameInput">
          <mat-label> {{ "lastName" | transloco }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="lastName"
            placeholder="Enter your last name"
            name="lastName"
          />
          <mat-error *ngIf="formErrors.lastName">{{
            formErrors.lastName
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="lg:flex xl:flex">
        <div class="lg:w-1/2 lg:mr-3">
          <mat-form-field
            class="mr-3 w-full"
            appearance="outline"
            id="countryInput"
          >
            <mat-label>{{ "country" | transloco }}</mat-label>
            <input
              type="text"
              placeholder="Enter your country"
              aria-label="Country"
              matInput
              formControlName="country"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                *ngFor="let country of filteredCountries"
                [value]="country.name"
                (click)="setPhoneCode(country.phoneCode)"
              >
                <div class="flex items-center">
                  <div class="w-20">
                    {{ country.phoneCode }}
                  </div>
                  <div>
                    {{ country.name }}
                  </div>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div class="lg:w-1/2 flex">
          <mat-form-field
            class="w-3/12"
            appearance="outline"
            id="phonePrefixInput"
          >
            <input
              matInput
              type="text"
              formControlName="prefix"
              placeholder="+90"
              name="prefix"
            />
            <mat-error *ngIf="formErrors.prefix">{{
              formErrors.prefix
            }}</mat-error>
          </mat-form-field>
          <mat-form-field
            class="flex-grow w-9/12"
            appearance="outline"
            id="phoneNumberInput"
          >
            <input
              matInput
              type="tel"
              formControlName="phoneNumber"
              placeholder="530 148 56 65"
              name="phoneNumber"
            />
            <mat-error *ngIf="formErrors.phoneNumber">{{
              formErrors.phoneNumber
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="lg:flex xl:flex">
        <mat-form-field class="w-full" appearance="outline" id="emailInput">
          <mat-label> {{ "email" | transloco }}</mat-label>
          <input
            matInput
            type="email"
            formControlName="email"
            placeholder="Enter your email address"
            name="email"
          />
          <mat-error *ngIf="formErrors.email">{{ formErrors.email }}</mat-error>
        </mat-form-field>
        <mat-form-field
          class="w-full"
          appearance="outline"
          id="companyNameInput"
        >
          <mat-label> {{ "companyName" | transloco }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="companyName"
            placeholder="Enter your company name"
            name="companyName"
          />
        </mat-form-field>
      </div>

      <div class="w-full">
        <mat-form-field class="w-full" appearance="outline" id="messageInput">
          <mat-label> {{ "message" | transloco }}</mat-label>
          <textarea
            matInput
            formControlName="message"
            placeholder="Enter your message"
            name="message"
          ></textarea>
          <mat-error *ngIf="formErrors.message">{{
            formErrors.message
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="py-3">
        <p *ngIf="successMSG" class="text-center text-green-600 text-xl">
          {{ successMSG }}
        </p>
        <button
          type="submit"
          [disabled]="contactForm.invalid"
          class="my-4 bg-[#E60027] text-white px-4 py-2 center rounded-md disabled:bg-[#ff798f] hover:bg-[#a7001c]"
        >
          {{ "sendMessage" | transloco }}
        </button>
      </div>
    </form>
  </div>
</div>
<div class="flex items-center justify-center mt-36 mb-4">
  <p
    class="text-center inline-block text-2xl border border-black px-5 py-1 rounded-full"
    [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'"
  >
    {{ "getInToutch" | transloco }}
  </p>
</div>
<div
  class="w-11/12 lg:w-5/6 xl:w-5/6 center px-8 pt-3 pb-5 rounded-2xl shadow-lg bg-[#dedede17]"
>
  <p
    class="text-center text-3xl"
    [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'"
  >
    {{ "salesDepartment" | transloco }}
  </p>
  <div class="w-full py-3">
    <hr />
  </div>
  <div class="grid lg:flex xl:flex">
    <div class="w-full lg:w-1/3 xl:w-1/3 grid justify-center">
      <a class="hover:text-[#E60027] text-xl" href="tel:+905301485665"
        ><i class="fas fa-mobile-alt"></i> : +90 530 148 56 65
      </a>
      <br />
      <a class="hover:text-[#E60027] text-xl" href="tel:+903425023161"
        ><i class="fas fa-phone-volume"></i> : +90 (342) 502 31 61
      </a>
    </div>
    <div
      class="w-full lg:w-2/3 xl:w-2/3 grid justify-center text-center mt-4 lg:mt-0 xl:mt-0"
    >
      <a
        class="hover:text-[#E60027] text-xl"
        href="mailto:info@zirvecompany.com"
        ><i class="fa-regular fa-envelope"></i> : info@zirvecompany.com
      </a>
      <br />
      <span
        ><i class="fa-solid fa-location-dot"></i>
        <a
          class="hover:text-[#E60027] text-xl"
          target="_blank"
          href="https://www.google.com/maps/place/Z%C4%B0RVE+%C4%B0NTERNAT%C4%B0ONAL/@37.0635676,37.4491954,17z/data=!4m5!3m4!1s0x1531e14ed879e753:0x8a654126dfac1b85!8m2!3d37.0635676!4d37.4513841"
        >
          : Sanayi Mah. 60352 sok . NO :29 Şehitkamil / Gaziantep / Türkiye
        </a>
      </span>
    </div>
  </div>
</div>
<div class="flex items-center justify-center mt-36 mb-4">
  <p
    class="text-center inline-block text-3xl border border-black px-5 py-1 rounded-full"
    [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'"
  >
    {{ "folowUs" | transloco }}
  </p>
</div>

<div
  class="w-11/12 lg:w-3/6 xl:w-3/6 center px-8 py-5 rounded-2xl shadow-lg bg-[#dedede17]"
>
  <div
    class="grid grid-cols-5 md:grid-cols-9 lg:grid-cols-8 justify-around items-center gap-5"
  >
    <a href="https://twitter.com/zirveextrusion" target="_blank" matTooltip="X">
      <img
        class="mx-auto"
        src="assets\contact\x.png"
        loading="lazy"
        alt="X Logo"
    /></a>
    <a
      href="https://www.facebook.com/zirveextrusion"
      target="_blank"
      matTooltip="Facebook"
    >
      <img
        class="mx-auto"
        src="assets\contact\face.png"
        loading="lazy"
        alt="X Logo"
      />
    </a>
    <a
      href="https://www.instagram.com/zirveextrusion/"
      target="_blank"
      matTooltip="Instagram"
    >
      <img
        class="mx-auto"
        src="assets\contact\insta.png"
        loading="lazy"
        alt="X Logo"
      />
    </a>
    <a
      href="https://www.youtube.com/channel/UCLvw13OLb5NJrE35Z9WkiRA"
      target="_blank"
      matTooltip="Youtube"
    >
      <img
        class="mx-auto"
        src="assets\contact\youtube.png"
        loading="lazy"
        alt="X Logo"
      />
    </a>
    <a href="https://wa.me/+905301485665" target="_blank" matTooltip="Whatsapp">
      <img
        class="mx-auto"
        src="assets\contact\whatsapp.png"
        loading="lazy"
        alt="X Logo"
      />
    </a>
    <a
      href="https://www.tiktok.com/@zirveextrusion"
      target="_blank"
      matTooltip="Tiktok"
    >
      <img
        class="mx-auto"
        src="assets\contact\tiktok.png"
        loading="lazy"
        alt="X Logo"
      />
    </a>
    <a
      href="https://www.linkedin.com/company/zirve-extrusion/about/"
      target="_blank"
      matTooltip="Linkedin"
    >
      <img
        class="mx-auto"
        src="assets\contact\linkedin.png"
        loading="lazy"
        alt="X Logo"
      />
    </a>
    <a
      href="https://tr.pinterest.com/zirveextrusion/"
      target="_blank"
      matTooltip="Pinterest"
    >
      <img
        class="mx-auto"
        src="assets\contact\preset.png"
        loading="lazy"
        alt="X Logo"
      />
    </a>
  </div>
</div>
<div [ngClass]="activeLang === 'AR' ? 'arabic' : 'english'">
  <div class="flex items-center justify-center mt-10 xl:mt-36 mb-4">
    <p
      class="text-center inline-block text-3xl border border-black px-5 py-1 rounded-full"
    >
      {{ "SALESMANAGERS" | transloco }}
    </p>
  </div>

  <div class="md:flex px-3 lg:px-40 text-center mt-10">
    <div class="w-full md:w-1/3">
      <img class="mx-auto" src="assets/contact/m3.webp" width="25%" alt="" />
      <a
        class="flex items-center justify-center mx-16"
        href="https://wa.me/+905301485665"
        target="_blank"
      >
        <img
          class="w-6 mr-1"
          src="assets\contact\whatsapp.png"
          loading="lazy"
          alt="X Logo"
        />
        <span class="text-blue-600"> {{ "directContact" | transloco }} </span>
      </a>
      <p class="text-base lg:text-2xl font-extrabold">Ghaith ZIDAN</p>
      <p class="font-bold">
        {{ "m3" | transloco }}
      </p>
    </div>
    <div class="w-full md:w-1/3">
      <img class="mx-auto" src="assets/contact/m2.webp" width="25%" alt="" />
      <a
        class="flex items-center justify-center mx-16"
        href="https://wa.me/+905497363701"
        target="_blank"
      >
        <img
          class="w-6 mr-1"
          src="assets\contact\whatsapp.png"
          loading="lazy"
          alt="X Logo"
        />
        <span class="text-blue-600"> {{ "directContact" | transloco }} </span>
      </a>
      <p class="text-base lg:text-2xl font-extrabold">Aiman HADRI</p>
      <p class="font-bold">
        {{ "m2" | transloco }}
      </p>
    </div>
    <div class="w-full md:w-1/3">
      <img class="mx-auto" src="assets/contact/m1.webp" width="25%" alt="" />
      <a
        class="flex items-center justify-center mx-16"
        href="https://wa.me/+905616116610"
        target="_blank"
      >
        <img
          class="w-6 mr-1"
          src="assets\contact\whatsapp.png"
          loading="lazy"
          alt="X Logo"
        />
        <span class="text-blue-600"> {{ "directContact" | transloco }} </span>
      </a>
      <p class="text-base lg:text-2xl font-extrabold">Bayram GÜZEL</p>
      <p class="font-bold">
        {{ "m1" | transloco }}
      </p>
    </div>
  </div>
</div>
<div class="my-60"></div>
