import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Renderer2 } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { GeneralService } from 'app/services/general.service';
import { Meta } from '@angular/platform-browser';
import { HreflangService } from 'app/services/hreflang.service';
import { COUNTRY_PHONE_CODES } from 'app/shared/countries';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  contactForm: FormGroup;
  scriptElement;
  countries: any[] = COUNTRY_PHONE_CODES;
  successMSG: string;
  activeLang: string = 'EN';
  firstRun: boolean;
  filteredCountries: any[];

  private langChangesSubscription: Subscription;
  formErrors = {
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    prefix: '',
    country: '',
    companyName: '',
    message: ''
  };
  validationMessages = {
    name: {
      required: 'Name is required.',
      minlength: 'Name must be at least 2 characters long.'
    },
    lastName: {
      required: 'Last Name is required.',
      minlength: 'Last Name must be at least 2 characters long.'
    },
    email: {
      required: 'Email is required.',
      email: 'Please enter a valid email address.'
    },
    phoneNumber: {
      required: 'Phone Number is required.'
    },
    prefix: {
      required: 'Required.'
    },
    country: {
      required: 'Country is required.'
    },
    message: {
      required: 'Message is required.',
      minlength: 'Message must be at least 10 characters long.'
    }
  };
  constructor(private fb: FormBuilder, private renderer: Renderer2, private _translocoService: TranslocoService, private generalServices: GeneralService, private meta: Meta, private hreflangService: HreflangService,) { }

  ngOnInit() {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.filteredCountries = this.countries;
    this.getActiveLang();
    this.createForm();
    this.addOrganizationSchema();

  }



  createForm() {
    this.contactForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      lastName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      country: ['', Validators.required],
      companyName: [''],
      prefix: ['', Validators.required],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });

    // Subscribe to value changes of the country input
    this.contactForm.get('country').valueChanges.subscribe(value => {
      this.filteredCountries = this._filterCountries(value);
    });

    this.contactForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // Reset validation messages now
  }

  private _filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country =>
      country.name.toLowerCase().includes(filterValue)
    );
  }

  setPhoneCode(phoneCode) {
    this.contactForm.get('prefix').setValue(phoneCode);
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      if (this.activeLang !== activeLang || !this.firstRun) {
        this.deleteSchemaMeta();
        setTimeout(() => {

          this.hreflangService.updateHreflangTags(activeLang, 'contact');
        }, 1000);
        this.firstRun = true;
      }
      this.activeLang = activeLang;
    });
  }

  onValueChanged(data?: any) {
    if (!this.contactForm) {
      return;
    }
    const form = this.contactForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const formData = {
        ...this.contactForm.value
      }
      this.generalServices.addItem('contact', formData).subscribe((res) => {
        if (res.status === 'success') {
          switch (this.activeLang) {
            case 'AR':
              this.successMSG = 'لقد تلقينا رسالتك وسوف نقوم بالرد في أقرب وقت ممكن';
              break;
            case 'EN':
              this.successMSG = 'We received your message and will respond as soon as possible';
              break;
            case 'FR':
              this.successMSG = 'Nous avons reçu votre message et vous répondrons dans les plus brefs délais';
              break;
            case 'RU':
              this.successMSG = 'Мы получили ваше сообщение и ответим как можно скорее';
              break;
            case 'TR':
              this.successMSG = 'Mesajınızı aldık ve en kısa sürede yanıt vereceğiz';
              break;

            default:
              break;
          }

        }
      })
    }
  }


  addOrganizationSchema() {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }

    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://zirvecompany.net",
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "+90-530-148-56-65",
          "contactType": "sales department"
        }
      ]
    });
    this.scriptElement = script;
    this.renderer.appendChild(document.head, script);
  }


  ngOnDestroy(): void {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
    // Delete Robots
    const robotTags = this.meta.getTags('name="robots"');
    if (robotTags.length > 0) {
      robotTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing robot tag:', error);
        }
      });
    }

    this.deleteSchemaMeta();
  }

  deleteSchemaMeta() {
    if (this.scriptElement) {
      this.renderer.removeChild(document.head, this.scriptElement);
      this.scriptElement = null;
    }
    // Delete Hreflang
    const hreflangTags = this.meta.getTags('name="hreflang"');
    if (hreflangTags.length > 0) {
      hreflangTags.forEach(tag => {
        try {
          this.meta.removeTagElement(tag);
        } catch (error) {
          console.error('Error removing hreflang tag:', error);
        }
      });
    }


    // Delete Canonical
    try {
      this.meta.removeTag('rel=canonical');
    } catch (error) {
      console.error('Error removing canonical tag:', error);
    }
  }

}