import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { GeneralService } from 'app/services/general.service';
import { COUNTRY_PHONE_CODES } from 'app/shared/countries';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-send-offer-req',
  templateUrl: './send-offer-req.component.html',
  styleUrls: ['./send-offer-req.component.scss']
})
export class SendOfferReqComponent implements OnInit, OnDestroy {
  @Input() machineId: string;
  @Input() machineName: string;
  contactForm: FormGroup;
  countries: any[] = COUNTRY_PHONE_CODES;
  viewRecieveMessage: boolean = false;
  activeLang: string = 'EN';
  private langChangesSubscription: Subscription;
  filteredCountries: any[];
  formErrors = {
    email: '',
    phoneNumber: '',
    country: '',
    companyName: '',
    prefix: '',
  };
  validationMessages = {
    companyName: {
      required: 'Company Name is required.',
      minlength: 'Company Name must be at least 2 characters long.'
    },
    email: {
      required: 'Email is required.',
      email: 'Please enter a valid email address.'
    },
    phoneNumber: {
      required: 'Phone Number is required.'
    },
    country: {
      required: 'Country is required.'
    },
    prefix: {
      required: 'Required.'
    },
  };

  constructor(private fb: FormBuilder, private generalServices: GeneralService,
    private _translocoService: TranslocoService) { }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.filteredCountries = this.countries;
    this.createForm();
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  createForm() {
    this.contactForm = this.fb.group({
      companyName: ['', [Validators.required, Validators.minLength(2)]],
      country: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      prefix: ['', Validators.required],
      howReachUs: [''],
    });

    this.contactForm.get('country').valueChanges.subscribe(value => {
      this.filteredCountries = this._filterCountries(value);
    });

    this.contactForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // Reset validation messages now
  }

  private _filterCountries(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country =>
      country.name.toLowerCase().includes(filterValue)
    );
  }

  setPhoneCode(phoneCode: string) {
    const prefixControl = this.contactForm.get('prefix');
    if (prefixControl) {
      prefixControl.setValue(phoneCode);
    }
  }

  onValueChanged(data?: any) {
    if (!this.contactForm) {
      return;
    }
    const form = this.contactForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    if (this.contactForm.valid) {
      const form = {
        ...this.contactForm.value,
        machineId: this.machineId,
        machineName: this.machineName
      };
      this.generalServices.addItem('productForm', form).subscribe(() => {
        this.viewRecieveMessage = true;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}
